import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import { Box, CheckBox, Heading, Video } from 'grommet';
import { Button } from 'components/Wrapped';
import { nestedTranslate } from 'utils/strings';
import WrappedParagraph from 'components/Wrapped/Paragraph';
import { useDispatch, useSelector } from 'react-redux';
import { updateOnboarding } from 'redux/actions/onboarding';
import { onboardingStatus } from 'utils/enums/onboardingStatus';
import { triggerToast } from 'redux/actions/ui';

const Orientation = ({ t: translate }) => {
  const { trustcoId } = useSelector(({ user }) => user.profile);

  const dispatch = useDispatch();
  const history = useHistory();

  const t = nestedTranslate(translate, 'account.onboarding.orientation');

  const [checkbox1, setCheckbox1] = useState(false);
  const [checkbox2, setCheckbox2] = useState(false);
  const [error, setError] = useState('');

  const onSubmit = () => {
    if (!checkbox1 || !checkbox2) {
      setError(t('error.missingCheckboxes'));
      setTimeout(() => setError(''), 5000);
      return;
    }

    dispatch(
      updateOnboarding({
        body: {
          isOrientationPassed: true,
          currentStep: onboardingStatus.ONBOARDED,
          subtype: onboardingStatus.ORIENTATION,
          data: 'Orientation passed',
        },
      }),
    );
    dispatch(triggerToast('orientationPassed', 'success'));
    history.push('/portfolio');
  };

  return (
    <>
      <Heading level={3}>{t('title')}</Heading>
      {/* TODO - add below when we have video + text */}
      {/* <Box margin={{ vertical: 'medium' }} direction="row" justify="center">
        <Box width="large">
          <Video width="large" controls="over" fit="cover">
            <source
              key="video"
              src="//v2.grommet.io/assets/small.mp4"
              type="video/mp4"
            />
          </Video>
        </Box>
      </Box>
      <Box margin={{ vertical: 'small' }}>
        <WrappedParagraph>{t('text')}</WrappedParagraph>
      </Box> */}
      <Box margin={{ top: 'large' }} direction="row">
        <CheckBox
          checked={checkbox1}
          onChange={event => setCheckbox1(event.target.checked)}
        />
        <WrappedParagraph margin={{ left: 'xsmall' }}>
          {t('checkbox1') + ' (Video will be presented above)'}
        </WrappedParagraph>
      </Box>
      <Box margin={{ vertical: 'medium' }} direction="row">
        <CheckBox
          checked={checkbox2}
          onChange={event => setCheckbox2(event.target.checked)}
          margin={{ right: 'xsmall' }}
        />
        <WrappedParagraph margin={{ left: 'xsmall' }}>
          I have read the
        </WrappedParagraph>
        <WrappedParagraph margin={{ horizontal: 'xsmall' }}>
          <a
            href="/documentation-files/HoverTrusts Privacy Policy.pdf"
            target="_blank"
          >
            Privacy Policy
          </a>
        </WrappedParagraph>
        <WrappedParagraph>and agree to the </WrappedParagraph>
        <WrappedParagraph margin={{ horizontal: 'xsmall' }}>
          <a href="/documentation-files/MOCK-t&c.pdf" target="_blank">
            Terms and Conditions
          </a>
        </WrappedParagraph>
      </Box>
      <Box direction="row" justify="start" pad="small">
        <Link to="/account">
          <Button
            margin={{ horizontal: 'xsmall' }}
            color="primary"
            primary={false}
          >
            {t('buttons.cancel')}
          </Button>
        </Link>
        <Button
          onClick={onSubmit}
          margin={{ horizontal: 'xsmall' }}
          color="primary"
        >
          {t('buttons.submit')}
        </Button>
      </Box>
      {error && (
        <WrappedParagraph textAlign="start" color="red">
          {error}
        </WrappedParagraph>
      )}
    </>
  );
};

export default withNamespaces()(Orientation);
